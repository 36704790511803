
import { defineComponent, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "admin-sub-product-table",
  components: {},
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      products: [{ name: "", Date: "", mainCategoryName: "" }],
    };
  },
  setup() {
    const { displayDatatable } = ResusableFunctions();

    onMounted(() => {
      displayDatatable(["basicExample"]);
    });
  },
  created() {
    this.getSubProducts();
  },
  methods: {
    getSubProducts() {
      //Fetch the list of the main insurance products
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.BANK_ROUTE)
          .then(({ data }) => {
            this.products = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
});
