
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import SubProductTable from "@/components/admin/SubProductTable.vue";
import SubCategoryModal from "@/components/modals/SubCategoryModal.vue";

export default defineComponent({
  name: "individual-client-report-claims",
  components: { SubProductTable, SubCategoryModal },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Sub Category", ["Insurance Products"]);
    });
  },
  created() {
    //Set page title
    document.title =
      "Insurance Products: Sub Category | " + process.env.VUE_APP_TITLE;
  }
});
